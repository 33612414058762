import './App.css';
import { AiFillGithub, AiFillInstagram, AiFillLinkedin ,AiOutlineMail } from 'react-icons/ai';
export const projects = [
  {
    title: 'MiddleMist NFTs Store',
    description: "MiddleMist marketplace that allows artists to publish and sell their NFTs, and allows buyers to bid and buy NFT with crypto currencies.",
      image: '/images/nft.png',
      tags: ["React", "Redux",  "REST APIs", "Metamask",  "Python"],
    source: 'https://middlemist.com',
    visit: 'https://middlemist.com',
    id: 0,
  },
  {
    title: 'dashboard Admin Panel',
    description: "Modern Responsive Admin Panel includes one Dashboard, Three Pages, Four Apps, and Seven fully functional charts!.",
      image: '/images/dashboard.png',
      tags: ['React','Syncfusion UI',  'Node' , "javascript"],
      visit: 'https://github.com/esraashreef/dashboard',
    source: 'https://dashboard222.netlify.app/',
    id: 6,
  },
  {
    title: 'Amazon clone',
    description: "Using React, Node.js, Express & Firebase, I tried to made amazon clone.",
      image: '/images/amazon.png',
      tags: ['firebase', 'Express', 'React', 'Node'],
    visit: 'https://github.com/esraashreef/amazon-clone',
    source: 'https://amazon-clone222.netlify.app/',
    id: 1,
  },
  
];
export const projects2 = [
  {
    title: 'How&Why',
    description: "Using Laravel, php, Mysql, I tried to made questions & answers website.",
      image: '/images/keif.png',
      tags: ['Laravel', 'Php', 'mysql', 'Node'],
    visit: 'https://github.com/esraashreef/keif.git',
    source: 'https://keif.herokuapp.com/',
    id: 2,
  },
  {
    title: 'Tesla clone',
    description: "Using React, Node.js, Express , I tried to made Tesla website clone.",
      image: '/images/tesla.png',
      tags: [ 'React','Express',  'Node'],
    visit: 'https://github.com/esraashreef/tesla-clone.git',
    source: 'http://tesla-c.herokuapp.com/',
    id: 3,
  },
  {
    title: 'Pet clinic',
    description:"With my small experience I tried to do a website with A blog to pet clinic .",
    image: '/images/pet.png',
    tags: ['Node', 'JavaScript','Express','MongoDB'],
    visit: 'https://github.com/esraashreef/petclinic',
    source: 'https://powerful-tor-67608.herokuapp.com/',
    id: 4,
  },

]
// 4FB629

function App() {
  return (
    <div className="App">
      <div className='navbar' style={{display:"flex" , backgroundColor:"black",opacity:"0.7" ,height: "40%",
    top: "30%",position: "fixed",    right: "0",width: "32px",flexDirection: "column",zIndex: "99" ,justifyContent:" center",
    alignItems: "center"}}>
        {/* <div style={{marginTop:"1%", marginRight:"1%" , display:"flex" ,flexDirection: "column"}}> */}
          <a href="https://github.com/esraashreef">
            <AiFillGithub class="s"style={{ color:"white"}} size="3rem" />
          </a>
          <a href="https://www.linkedin.com/in/esraa-shreef-6663524a/">
            <AiFillLinkedin class="s"style={{ color:"blue"}} size="3rem" />
          </a>
          <a href="https://instagram.com">
            <AiFillInstagram class="s"style={{ color:"red"}}  size="3rem"/>
          </a>
          <a href="mailto:esraa.shreef@gmail.com">
            <AiOutlineMail class="s"style={{ color:"rgb(123, 117, 162)"}}  size="3rem"/>
          </a>
        {/* </div> */}
        
      </div>
      <header class="header" style={{backgroundImage: "linear-gradient(to right bottom, rgba(0, 0, 0, 0.8), rgba(40, 180, 133, 0.4)), url(/hero.jpg)"}}>
           
            <div class="header__text-box">
                <h1 class="heading-primary">
                    <span class="heading-primary--main">Hi There, I'm</span>
                    <h6 class="heading-primary--sub" style={{marginTop:"8%", marginBottom:"8%"}}>Esraa Shreef</h6>
                  
          
                    <span class="heading-primary--main">frontend developer</span>
                </h1>

                <a href="mailto:esraa.shreef@gmail.com" class="btn btn--white btn--animated">contact me</a>
            </div>
        </header>
        <section class="section-tours" id="section-tours">
                <div class="u-center-text u-margin-bottom-big">
                    <h2 class="heading-secondary" style={{textTransform: "capitalize" ,color:"#469827"}}>
                        my projects
                    </h2>
                </div>


                <div class="row">
                  {projects.map(p=>{
                     return( <div class="col-1-of-3">
                      <div class="card">
                          <div class="card__side card__side--front">
                              <div style={{backgroundImage:`url(${p.image})`}} class="card__picture card__picture--2">
                                  &nbsp;
                              </div>
                              <h4 class="card__heading">
                                  
                              </h4>
                              <div class="card__details" style={{fontSize:"1.5rem"}}>
                              <h6 class="card__heading-span card__heading-span--2" style={{fontSize:"2.1rem" , color:"#469827"}}>{p.title}</h6>
                                <span>{p.description}</span>
                              </div>

                          </div>
                          <div class="card__side card__side--back card__side--back-2">
                              <div class="card__cta">
                                  <div class="card__price-box">
                                  <p class="card__price-value">to view the project</p>
                                  </div>
                                  <a href={p.source} class="btn btn--white">click here</a>
                              </div>
                          </div>
                      </div>
                  </div>


                  )})}
                  
                </div>
                <div class="row">
                  {projects2.map(p=>{
                     return( <div class="col-1-of-3">
                      <div class="card">
                          <div class="card__side card__side--front">
                              <div style={{backgroundImage:`url(${p.image})`}} class="card__picture card__picture--2">
                                  &nbsp;
                              </div>
                              <h4 class="card__heading">
                                  
                              </h4>
                              <div class="card__details" style={{fontSize:"1.5rem"}}>
                              <h6 class="card__heading-span card__heading-span--2" style={{fontSize:"2.1rem" , color:"#469827"}}>{p.title}</h6>
                                <span>{p.description}</span>
                              </div>

                          </div>
                          <div class="card__side card__side--back card__side--back-2">
                              <div class="card__cta">
                                  <div class="card__price-box">
                                  <p class="card__price-value">to view the project</p>
                                  </div>
                                  <a href={p.source} class="btn btn--white">click here</a>
                              </div>
                          </div>
                      </div>
                  </div>


                  )})}
                  
                </div>

                {/* <div class="u-center-text u-margin-top-huge">
                    <a href="" class="btn btn--green">Discover all tours</a>
                </div> */}
            </section>
            <section class="section-features">
            <h3 class="heading-tertiary " style={{ fontSize:"3rem",   display: "flex",
    justifyContent: "center",
    color: "white",
     marginBottom:" 8%" ,textTransform: "capitalize"}}>Technologies</h3>
                <div class="row">
                
                    <div class="col-1-of-2">
                        <div class="feature-box">
                            <i class="feature-box__icon icon-basic-world"></i>
                            <h3 class="heading-tertiary u-margin-bottom-small">Front-End</h3>
                            <p class="feature-box__text">
                            Experience with React.js, .Javascript ,Redux,REST APIs, Bootstrap, CSS, Typescript
                            </p>
                        </div>
                    </div>

                    <div class="col-1-of-2">
                        <div class="feature-box">
                            <i class="feature-box__icon icon-basic-compass"></i>
                            <h3 class="heading-tertiary u-margin-bottom-small">Back-End</h3>
                            <p class="feature-box__text">
                             some Experience with Laravel, PHP, Node and Databases
                            </p>
                        </div>
                    </div>

                    {/* <div class="col-1-of-4">
                        <div class="feature-box">
                            <i class="feature-box__icon icon-basic-map"></i>
                            <h3 class="heading-tertiary u-margin-bottom-small">Find your way</h3>
                             <p class="feature-box__text">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, ipsum sapiente aspernatur.
                            </p>
                        </div>
                    </div>

                    <div class="col-1-of-4">
                        <div class="feature-box">
                            <i class="feature-box__icon icon-basic-heart"></i>
                            <h3 class="heading-tertiary u-margin-bottom-small">Live a healthier life</h3>
                            <p class="feature-box__text">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, ipsum sapiente aspernatur.
                            </p>
                        </div>
                    </div> */}
                </div>
            </section>
            <section   style={{marginTop:"10%", letterSpacing: "1px"}}>

                        <h3  style={{textTransform: "capitalize" ,color:"#469827", fontSize:"3rem",   display: "flex",
                justifyContent: "center",}}>about me</h3>
                <h3 class="heading-tertiary u-margin-bottom-small" style={{padding:"9rem" , fontWeight:"500"}}>A Frontend Developer with experience building web applications using ReactJs, Redux, NodeJs, PHP, Laravel, Python, and MySQL. Always looking forward to learning something new and building world class software products while following the right best practices.</h3>
            </section>

    </div>
  );
}

export default App;
